import { getStatuses } from '@/modules/order-tracking/order-tracking.api.js';

export function useStatuses() {
    const fetchStatuses = async callback => {
        try {
            const { data } = await getStatuses();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchStatuses,
    };
}
