<template>
    <div class="flex flex-col items-center text-center gap-4 w-full">
        <Icon
            name="ban"
            class="fa-3x"
        />
        <h1>
            {{ $t('general.unauthorized') }}
        </h1>
        <p>{{ $t('general.unauthorizedMessage', { route: route.redirectedFrom.fullPath }) }}</p>
    </div>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

</script>
