<template>
    Logging out...
</template>

<script setup>
import { useGlobalStore } from '@/stores/global-store.js';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

const store = useGlobalStore();
const router = useRouter();

onMounted(async () => {
    await store.logout();
    await router.go(0);
});

</script>
