<template>
    <div
        :data-before="before || undefined"
        :class="[
            {'relative before:block before:absolute before:z-1 before:content-[attr(data-before)] before:ml-1.5 before:mt-7.75': before},
            containerClass || 'my-1 sm:my-3 h-[5.125rem]'
        ]"
    >
        <div class="flex flex-col">
            <label
                v-if="label"
                :for="id"
                class="text-sm mb-1 truncate"
            >
                {{ label }}
                <span
                    v-if="showOptional && !isRequired && !disabled"
                    class="text-grey-dark"
                >({{ $t('general.labelOptional') }})</span>
            </label>
            <div class="relative">
                <input
                    :id="id"
                    v-model="displayValue"
                    v-bind="$attrs"
                    :type="type"
                    :required="isRequired"
                    :disabled="disabled"
                    :autocomplete="$attrs.autocomplete || (type === 'password' ? 'current-password' : id)"
                    :data-test-id="id"
                    formnovalidate
                    class="px-2 py-1.5 outline-none border focus:border-secondary focus:ring-1 focus:ring-secondary w-full transition"
                    :class="[errors[id] ? 'border-orange-dark' : 'border-grey', {'pl-6': before}]"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                    @change="onChange"
                >
                <span
                    v-if="disabled"
                    class="absolute flex items-center justify-center -top-3 -right-3 bg-white text-grey border border-grey rounded w-6 h-6 leading-tight"
                >
                    <Icon
                        name="lock"
                        class="text-xs align-baseline"
                    />
                </span>
            </div>
            <span
                v-if="errors[id] || apiErrorMessages[id]"
                class="text-orange-dark text-xs mt-0.5 sentence-case"
            >
                {{ errors[id] || apiErrorMessages[id] }}
            </span>
        </div>
    </div>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import { useFormValidation } from '@/composables/use-form-validation';
import { useNumberUtils } from '@/composables/use-number-utils';
import {
    computed, onBeforeUnmount, ref, useAttrs,
} from 'vue';
import { useI18n } from 'vue-i18n';

const attrs = useAttrs();

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },

    modelValue: {
        type: [String, Number],
        default: null,
    },

    modelModifiers: {
        type: Object,
        default() {
            return {};
        },

        validate(value) {
            const isValid = ['number', 'currency', 'percent'].some(modifier => value[modifier]);

            if (!isValid) {
                console.warn(`Invalid v-model modifier found in "${value}"`);
            }

            return isValid;
        },
    },

    id: {
        type: String,
        default() {
            return Date.now().toString();
        },
    },

    label: {
        type: String,
        default: null,
    },

    validationRules: {
        type: String,
        default: null,
    },

    validationMessages: {
        type: String,
        default: null,
    },

    showOptional: {
        type: Boolean,
        default: true,
    },

    containerClass: {
        type: [String, Array, Object],
        default: null,
    },

    before: {
        type: String,
        default: null,
    },

    currencyCode: {
        type: String,
        default: null,
    },

    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    'update:modelValue',
    'change',
    'inputBlur',
]);

const { t } = useI18n();
const {
    validate,
    errors,
    apiErrorMessages,
} = useFormValidation(t);

const {
    formatAsCurrency,
    formatAsPercentage,
    numberToString,
    isDecimalAllowed,
    normalizeDecimalSeparator,
    getMinValue,
} = useNumberUtils(props.currencyCode);

const isInputFocused = ref(false);
const displayValue = computed({
    get() {
        switch (true) {
            case Boolean(props.modelModifiers.currency):
                return isInputFocused.value ? numberToString(props.modelValue) : formatAsCurrency(props.modelValue, props.currencyCode);
            case Boolean(props.modelModifiers.percent):
                return isInputFocused.value ? normalizeDecimalSeparator(props.modelValue || '0', Number(attrs.max)) : formatAsPercentage(props.modelValue);
            case Boolean(props.modelModifiers.number):
            default:
                return attrs.value ?? props.modelValue;
        }
    },
    set(modifiedValue) {
        emit('update:modelValue', modifiedValue);
    },
});

const onFocus = () => {
    isInputFocused.value = true;
};

const onBlur = event => {
    isInputFocused.value = false;

    if (!props.disabled) {
        validate(props.id, event.target.value, props?.validationRules, props?.validationMessages);
    }

    emit('inputBlur', handleInput(event));
};

const onInput = event => {
    emit('update:modelValue', handleInput(event));
};

const onChange = event => {
    emit('change', handleInput(event));
};

const handleInput = event => {
    if (!props.modelModifiers.currency && !props.modelModifiers.number) {
        return event.target.value;
    }

    const minValue = attrs.min ? Number(attrs.min) : 0;
    const maxValue = attrs.max ? Number(attrs.max) : 1000 * 1000 * 1000 * 1000; // 1 trillion
    const value = event.target.value ? event.target.value : 0;

    const newValue = isDecimalAllowed ? parseFloat(value) : parseInt(value, 10);

    event.target.value = String(getMinValue(newValue, maxValue, minValue));

    if (isDecimalAllowed && event.type === 'input') {
        const dotAndZerosRegex = /^[0-9]+(\.0{0,2})0*$/.exec(value);
        const dotAndZeros = dotAndZerosRegex ? dotAndZerosRegex[1] || null : null; // e.g., 8., 8.0 and 8.00 and

        if (Boolean(dotAndZeros)) {
            event.target.value += dotAndZeros;

            return event.target.value;
        }

        const trailingZerosAfterNonZeroFirstDecimal = /^[0-9]+\.[1-9]0+$/.test(value); // e.g., 0.90 and 10.90

        if (trailingZerosAfterNonZeroFirstDecimal) {
            event.target.value += '0';
        }

        // Get the value with max 2 decimals
        // e.g., match 10.00 in 10.000 and 9.99 in 9.998
        const [match] = event.target.value.match(/^([0-9]+(\.[0-9]{0,2})?)?/);

        if (match) {
            event.target.value = match;
        }
    }

    return event.target.value;
};

const isRequired = props.validationRules ? props.validationRules.split('|').includes('required') : false;

onBeforeUnmount(() => {
    if (errors[props.id]) {
        delete errors[props.id];
    }
});

</script>

<style scoped>
input[disabled] {
    @apply text-grey focus:outline-none border-grey bg-white pointer-events-none;
}
</style>
