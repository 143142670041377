import { useGlobalStore } from '@/stores/stores.js';
import { reactive } from 'vue';

export function useAuth() {
    const store = useGlobalStore();
    const hasPermission = permission => store.permissions.includes(permission);
    // If you are thinking of adding a hasRole check.
    // Please don't. We have a permission for every specific role,
    // and permissions are more flexible than roles.
    const submitButton = reactive({
        inProgress: false,
    });

    return {
        store,
        submitButton,
        hasPermission,
    };
}
