import { getReleaseStatuses } from '@/modules/purchase-orders/products.api.js';

export function useReleaseStatuses() {
    const fetchReleaseStatuses = async callback => {
        try {
            const { data } = await getReleaseStatuses();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchReleaseStatuses,
    };
}
