import * as validators from '@/composables/use-form-validators';
import { useGlobalStore } from '@/stores/stores.js';
import { storeToRefs } from 'pinia';
import { reactive } from 'vue';

const errors = reactive({});

export function useFormValidation(t) {
    const store = useGlobalStore();
    const { apiErrorMessages } = storeToRefs(store);
    const triggerValidation = (elements = 'input[required]') => {
        const nodes = (typeof elements === 'string') ? document.querySelectorAll(elements) : elements;

        Array.from(nodes).forEach(node => {
            node.focus();
            node.blur();
        });
    };

    /**
     * Returns `false` if there are no errors for the given field. Otherwise, returns error message
     * @param {string} field
     * @param {any} value
     * @param {?string} [validationRules]
     * @param {?string} [validationMessages]
     * @return {string|false}
     */
    const validate = (field, value, validationRules = null, validationMessages = null) => {
        store.clearApiErrors();

        if (!validationRules) {
            return false;
        }

        if (validationMessages && !validationMessages.includes(':')) {
            throw new SyntaxError('Validation message should have a valid format, for instance: `required:general.errorMessages.requiredField`');
        }

        const validations = validationRules.split('|');
        const messages = validationMessages
            ? (validationMessages.includes('|') ? Object.fromEntries(validationMessages.split('|').map(message => message.split(':'))) : Object.fromEntries([validationMessages.split(':')]))
            : null;

        for (const validator of validations) {
            const hasLimitingRule = validator.includes(':');

            if (hasLimitingRule) {
                const [validatorName, num] = validator.split(':');

                errors[field] = validators[validatorName](value, num) ? false : t(messages && messages[validatorName] ? messages[validatorName] : `general.errorMessages.${validatorName}`, {
                    num,
                });
            } else {
                errors[field] = validators[validator](value)
                    ? false
                    : t(messages && messages[validator] ? messages[validator] : `general.errorMessages.${validator}`, {
                        field: field.replace(/-/g, ' '),
                    });
            }

            if (errors[field]) {
                break;
            }
        }

        return errors[field];
    };

    return {
        errors,
        apiErrorMessages,
        validate,
        triggerValidation,
    };
}
