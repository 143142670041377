import { setUpApi } from '@/api/api.js';
import { setUpIcons } from '@/assets/icons/icons-lib.js'; // Fontawesome svg icons as Vue components
import { setUpI18n } from '@/i18n.js';
import { router } from '@/router/router.js';
import { createPinia } from '@/stores/stores.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp } from 'vue';
import App from './App.vue';
import { useEcho } from '@/composables/use-echo.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

const bugsnagVue = Bugsnag.getPlugin('vue');

const bugSnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;
const releaseStage = import.meta.env.VITE_RELEASE_STAGE;

if (bugSnagApiKey?.length) {
    Bugsnag.start({
        apiKey: bugSnagApiKey,
        plugins: [new BugsnagPluginVue()],
        releaseStage,
    });
}

setUpIcons();
setUpApi(router); // NOTE: can't import router directly in api.js due to circular dependency

const app = createApp(App)
    .use(setUpI18n(localStorage.getItem('userLocale')))
    .use(createPinia())
    .use(router)
    .component('FontAwesomeIcon', FontAwesomeIcon);

app.config.errorHandler = error => {
    if (bugSnagApiKey?.length) {
        Bugsnag.notify(error);
    } else {
        console.error(error);
    }
};

app.mount('#app');

useEcho().setupEcho();
