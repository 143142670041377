import { api } from '@/api/api.js';

export function getStatuses() {
    return api.get('/order-tracking/statuses');
}

export function markAsFixedTargetSchedule(purchaseOrderIds) {
    return api.post('/order-tracking-orders/batch/fixed-target-schedule/mark', {
        purchaseOrderIds,
    });
}

export function unmarkAsFixedTargetSchedule(purchaseOrderIds) {
    return api.post('/order-tracking-orders/batch/fixed-target-schedule/unmark', {
        purchaseOrderIds,
    });
}

export function getOrderTrackedLines(params) {
    return api.get('/order-tracking-lines', {
        params: {
            page: 1,
            perPage: 25,
            sort: 'trading_code:asc',
            ...params,
        },
    });
}

export function getOrderTrackedLineReport(params) {
    return api.get('/order-tracking-lines/report', {
        params: {
            page: 1,
            perPage: 25,
            sort: 'trading_code:asc',
            ...params,
        },
    });
}

export function rejectOrderTrackingLines(ids, reviewComment) {
    return api.post('/order-tracking-lines/batch/reject', {
        ids,
        reviewComment,
    });
}

export function approveOrderTrackingLines(ids, reviewComment) {
    return api.post('/order-tracking-lines/batch/approve', {
        ids,
        reviewComment,
    });
}

export function downloadOrderTrackingLines(params) {
    return api.get('/order-tracking-lines/download', {
        params,
    });
}

export function downloadOrderTrackingLineReport(params) {
    return api.get('/order-tracking-lines/report/download', {
        params,
    });
}

export function downloadFailedOrderTrackingFile(fileName) {
    return api.get(`/order-tracking-lines/failed/${fileName}`);
}

export function uploadOrderTrackingLines(payload) {
    return api.post('/order-tracking', payload, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

export function getOrderTrackingLine(id) {
    return api.get(`/order-tracking-lines/${id}`);
}

export function updateOrderTrackingLine(id, payload) {
    return api.patch(`/order-tracking-lines/${id}`, payload);
}

export function approveOrderTrackingLine(id, payload) {
    return api.patch(`/order-tracking-lines/${id}/approvals/approve`, payload);
}

export function rejectOrderTrackingLine(id, payload) {
    return api.patch(`/order-tracking-lines/${id}/approvals/reject`, payload);
}

export function lockOrderTrackingLine(id) {
    return api.patch(`/order-tracking-lines/${id}/approvals/lock`, {});
}

export function unlockOrderTrackingLine(id) {
    return api.patch(`/order-tracking-lines/${id}/approvals/unlock`, {});
}

export function exportOrderTrackingLine(id) {
    return api.post(`/order-tracking-lines/${id}/export`);
}

export function getOrderTrackingActivity(id) {
    return api.get(`/order-tracking-activity/${id}`);
}
