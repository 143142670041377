import { defineStore } from 'pinia';
import { usePartners } from '@/modules/partners/use-partners.js';
import { useRegions } from '@/modules/purchase-orders/use-regions.js';
import { useDeliveryLocations } from '@/modules/purchase-orders/use-delivery-locations.js';
import { usePurchasingGroups } from '@/modules/purchase-orders/use-purchasing-groups.js';
import { useReleaseStatuses } from '@/modules/purchase-orders/use-release-statuses.js';
import { useDropdownOptions } from '@/composables/use-dropdown-options.js';
import { useStatuses } from '@/modules/order-tracking/use-statuses.js';
import { usePriceStatusControls } from '@/modules/purchase-orders/use-price-status-controls.js';
import { useStatusUpdates } from '@/modules/status-updates/use-status-updates.js';

const {
    fetchVendors,
    fetchBuyingCompanies,
} = usePartners();

const { fetchPublishedStatusUpdates } = useStatusUpdates();
const { fetchRegions } = useRegions();
const { fetchDeliveryLocations } = useDeliveryLocations();
const { fetchPurchasingGroups } = usePurchasingGroups();
const { fetchPriceStatusControls } = usePriceStatusControls();
const { fetchReleaseStatuses } = useReleaseStatuses();
const { fetchStatuses } = useStatuses();
const { getDropdownOptions } = useDropdownOptions();

/* eslint-disable */
export const useOptionsStore = defineStore('options', {
    state: () => ({
        metaDataLoaded: false,
        statusUpdates: [],
        regionOptions: [],
        deliveryLocationOptions: [],
        releaseStatusOptions: [],
        orderTrackingStatusOptions: [],
        priceStatusControlOptions: [],
        vendorOptions: [],
        buyingCompanyOptions: [],
        purchasingGroupOptions: [],
        statusOptions: [
            {
                value: 'Open',
                text: 'general.open',
            },
            {
                value: 'Shipped',
                text: 'general.shipped',
            },
            {
                value: 'Cancelled',
                text: 'general.cancelled',
            },
        ],
        orderTrackingShippedstatusOptions: [
            {
                value: 'Open',
                text: 'general.open',
            },
            {
                value: 'Shipped',
                text: 'general.shipped',
            },
        ],
        sentStatusOptions: [
            {
                value: 'toBeSent',
                text: 'general.status.toBeSent',
            },
            {
                value: 'sent',
                text: 'general.status.sent',
            },
            {
                value: 'notSent',
                text: 'general.status.notSent',
            },
            {
                value: 'failed',
                text: 'general.status.failed',
            },
        ],
        downloadedOptions: [
            {
                value: 'downloaded',
                text: 'general.downloaded',
            },
            {
                value: 'not-downloaded',
                text: 'general.notDownloaded',
            },
        ],
        prepackViewOptions: [
            {
                value: 'prepack',
                text: 'general.yes',
            },
        ],
        splitViewOptions: [
            {
                value: '',
                text: 'general.no',
            },
            {
                value: 'splits',
                text: 'general.yes',
            },
        ],
        withChangesOptions: [
            {
                value: 'yes',
                text: 'general.yes',
            },
        ],
        fixedTargetScheduleOptions: [
            {
                value: 'yes',
                text: 'general.yes',
            },
            {
                value: 'no',
                text: 'general.no',
            },
        ],
        handoverDateOptions: [
            {
                value: 'yes',
                text: 'general.yes',
            },
            {
                value: 'no',
                text: 'general.no',
            },
        ],
    }),

    actions: {
        async fetchMetaData() {
            await Promise.all([
                await fetchPublishedStatusUpdates(data => {
                    Object.assign(this.statusUpdates, data);
                }),
                await fetchVendors(data => {
                    Object.assign(this.vendorOptions, getDropdownOptions(data, 'id', 'idShortName'));
                }),
                fetchRegions(data => {
                    Object.assign(this.regionOptions, getDropdownOptions(data, 'id', 'name'));
                }),
                fetchDeliveryLocations(data => {
                    Object.assign(this.deliveryLocationOptions, getDropdownOptions(data, 'id', 'name'));
                }),
                fetchBuyingCompanies(data => {
                    Object.assign(this.buyingCompanyOptions, getDropdownOptions(data, 'id', 'idShortName'));
                }),
                fetchReleaseStatuses(data => {
                    Object.assign(this.releaseStatusOptions, getDropdownOptions(data, 'id', 'name'));
                }),
                fetchStatuses(data => {
                    Object.assign(this.orderTrackingStatusOptions, getDropdownOptions(data, 'id', 'name'));
                }),
                fetchPriceStatusControls(data => {
                    Object.assign(this.priceStatusControlOptions, getDropdownOptions(data, 'id', 'name'));
                }),
                fetchPurchasingGroups(data => {
                    Object.assign(this.purchasingGroupOptions, getDropdownOptions(data, 'id', 'name'));
                }),
            ]);

            this.metaDataLoaded = true;
        },
    },
});
