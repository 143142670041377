<template>
    <nav class="text-sm">
        <ul class="flex flex-wrap">
            <li
                v-for="(breadcrumb, index) in breadcrumbs"
                :key="index"
            >
                <Link
                    v-if="(index < breadcrumbs.length - 1) && breadcrumb.path"
                    :link-text="breadcrumb.prop ? breadcrumb.name : $t(breadcrumb.name)"
                    :link-url="breadcrumb.path"
                />
                <span
                    v-else
                    class="text-grey-dark"
                >
                    {{ breadcrumb.prop ? breadcrumb.name : $t(breadcrumb.name) }}
                </span>
                <Icon
                    v-if="index < breadcrumbs.length - 1"
                    name="caret-right"
                    class="text-grey mx-2"
                />
            </li>
        </ul>
    </nav>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import Link from '@/components/common/link/Link.vue';
import { interpolate } from '@/utils';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const breadcrumbs = ref(null);

const setBreadcrumbs = () => {
    breadcrumbs.value = route.matched.filter(route => Boolean(route.meta.breadcrumbs))
        .reduce((accumulator, current) => [...accumulator, ...current.meta.breadcrumbs], [])
        .map(item => {
            const routeParam = route.params[item.prop];
            const name = routeParam || item.name;

            return {
                ...item,
                name,
                path: routeParam ? interpolate(item.path, {
                    [item.prop]: routeParam,
                }) : item.path,
            };
        });
};

setBreadcrumbs();

watch(() => route.fullPath, setBreadcrumbs);

</script>
