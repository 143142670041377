function runMiddlewares(middlewares, to, from, next) {
    const middlewareQueue = [...middlewares];
    const nextMiddleware = middlewareQueue.shift();

    if (!nextMiddleware) {
        next();

        return;
    }

    nextMiddleware(to, from, nextArg => {
        if (!nextArg) {
            runMiddlewares(middlewareQueue, to, from, next);

            return;
        }

        next(nextArg);
    });
}

function guards(middlewares) {
    return (to, from, next) => runMiddlewares(middlewares, to, from, next);
}

export {
    guards,
};
