import Authenticated from '@/components/layout/Authenticated.vue';
import EmptyRouterView from '@/components/layout/EmptyRouterView.vue';
import Unauthenticated from '@/components/layout/Unauthenticated.vue';
import Home from '@/modules/home/Home.vue';
import Logout from '@/modules/logout/Logout.vue';
import NotFound from '@/modules/not-found/NotFound.vue';
import Login from '@/modules/unauthenticated/login/Login.vue';
import Unauthorized from '@/modules/unauthorized/Unauthorized.vue';

export const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound,
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/modules/maintenance/MaintenancePage.vue'),
    },
    {
        path: '',
        component: Authenticated,
        name: 'authenticated',
        redirect: '/home',
        meta: {
            auth: true,
        },
        children: [
            {
                path: '/unauthorized',
                name: 'unauthorized',
                component: Unauthorized,
                beforeEnter(to) {
                    if (!to.redirectedFrom) {
                        return {
                            name: 'home',
                        };
                    }
                },
            },
            {
                path: '/logout',
                component: Logout,
            },
            {
                path: '/home',
                name: 'home',
                component: Home,
                beforeEnter(to, from, next) {
                    // Force redirect to purchase orders
                    // as home page is currently not in use.
                    next('/purchase-orders');
                },
            },
            {
                path: '/purchase-orders',
                component: EmptyRouterView,
                meta: {
                    breadcrumbs: [
                        {
                            name: 'purchaseOrders.title',
                            path: '/purchase-orders',
                        },
                    ],
                    menu: {
                        icon: 'file-lines',
                        text: 'purchaseOrders.title',
                    },
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/purchase-orders/Products.vue'),
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/purchase-orders/purchase-order/PurchaseOrder.vue'),
                        props: true,
                        meta: {
                            breadcrumbs: [
                                {
                                    prop: 'id',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: '/order-tracking',
                redirect: '/order-tracking/order-tracking-lines',
                meta: {
                    permissions: ['order-tracking.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'orderTracking.title',
                            path: '/order-tracking',
                        },
                    ],
                    menu: {
                        icon: 'truck',
                        text: 'orderTracking.title',
                    },
                },
                children: [
                    {
                        path: 'order-tracking-lines',
                        permissions: ['order-tracking.approve'],
                        component: EmptyRouterView,
                        meta: {
                            breadcrumbs: [
                                {
                                    name: 'orderTracking.title',
                                    path: '/order-tracking/order-tracking-lines',
                                },
                            ],
                            submenu: {
                                text: 'orderTracking.title',
                                icon: 'route',
                            },
                        },
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/order-tracking/OrderTrackingLines.vue'),
                            },
                            {
                                path: ':id',
                                component: () => import('@/modules/order-tracking/order-tracking-line/OrderTrackingLine.vue'),
                                props: true,
                                meta: {
                                    permissions: ['order-tracking.view'],
                                    breadcrumbs: [
                                        {
                                            prop: 'id',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        path: 'fixed-target-schedule',
                        component: EmptyRouterView,
                        meta: {
                            permissions: ['order-tracking.approve'],
                            breadcrumbs: [
                                {
                                    name: 'fixedTargetSchedule.title',
                                    path: '/order-tracking/fixed-target-schedule',
                                },
                            ],
                            submenu: {
                                text: 'fixedTargetSchedule.title',
                                icon: 'calendar-check',
                            },
                        },
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/order-tracking/fixed-target-schedule/FixedTargetSchedule.vue'),
                            },
                        ],
                    },
                    {
                        path: 'reports',
                        component: EmptyRouterView,
                        meta: {
                            permissions: ['order-tracking.viewAny'],
                            breadcrumbs: [
                                {
                                    name: 'reports.title',
                                    path: '/order-tracking/reports',
                                },
                            ],
                            submenu: {
                                text: 'reports.title',
                                icon: 'chart-simple',
                            },
                        },
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/order-tracking/report/OrderTrackingLineReport.vue'),
                            },
                        ],
                    },
                    // {
                    //     path: 'auto-approval',
                    //     component: EmptyRouterView,
                    //     meta: {
                    //         permissions: ['order-tracking.approve'],
                    //         breadcrumbs: [
                    //             {
                    //                 name: 'general.autoApprovalSimulation',
                    //                 path: '/order-tracking',
                    //             },
                    //         ],
                    //         submenu: {
                    //             icon: 'check-circle',
                    //             text: 'general.autoApprovalSimulation',
                    //         },
                    //     },
                    //     children: [
                    //         {
                    //             path: '',
                    //             component: () => import('@/modules/order-tracking/auto-approval-simulation/AutoApprovalSimulation.vue'),
                    //         },
                    //     ],
                    // },
                    {
                        path: 'activity',
                        component: EmptyRouterView,
                        meta: {
                            permissions: ['order-tracking.approve'],
                            breadcrumbs: [
                                {
                                    name: 'general.activity',
                                    path: '/activity',
                                },
                            ],
                            submenu: {
                                icon: 'clock-rotate-left',
                                text: 'general.activity',
                            },
                        },
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/order-tracking/activity/OrderTrackingLines.vue'),
                            },
                            {
                                path: ':id',
                                component: () => import('@/modules/order-tracking/activity/OrderTrackingActivity.vue'),
                                props: true,
                                meta: {
                                    permissions: ['order-tracking.approve'],
                                    breadcrumbs: [
                                        {
                                            prop: 'id',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: '/vendors',
                component: EmptyRouterView,
                meta: {
                    permissions: ['partner.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'vendors.title',
                            path: '/vendors',
                        },
                    ],
                    menu: {
                        icon: 'industry',
                        text: 'vendors.title',
                    },
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/partners/Vendors.vue'),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/partners/vendor/VendorNew.vue'),
                        meta: {
                            permissions: ['partner.create'],
                            breadcrumbs: [
                                {
                                    name: 'newVendor.title',
                                },
                            ],
                        },
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/partners/vendor/Vendor.vue'),
                        props: true,
                        meta: {
                            permissions: ['partner.view'],
                            breadcrumbs: [
                                {
                                    prop: 'id',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: '/purchase-order-audits',
                component: EmptyRouterView,
                meta: {
                    permissions: ['purchase-order-audit.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'purchase-order-audits.title',
                            path: '/purchase-order-audits',
                        },
                    ],
                    menu: {
                        icon: 'download',
                        text: 'purchase-order-audits.title',
                    },
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/purchase-order-audits/PurchaseOrderAudits.vue'),
                    },
                ],
            },
            {
                path: '/users',
                component: EmptyRouterView,
                meta: {
                    admin: true,
                    permissions: ['user.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'users.title',
                            path: '/users',
                        },
                    ],
                    menu: {
                        admin: true,
                        icon: 'users',
                        text: 'users.title',
                    },
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/users/Users.vue'),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/users/user/UserNew.vue'),
                        meta: {
                            permissions: ['user.create'],
                            breadcrumbs: [
                                {
                                    name: 'newUser.title',
                                },
                            ],
                        },
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/users/user/User.vue'),
                        props: true,
                        meta: {
                            permissions: ['user.view'],
                            breadcrumbs: [
                                {
                                    prop: 'id',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: '/translations',
                component: () => import('@/modules/translations/Translations.vue'),
                meta: {
                    admin: true,
                    permissions: ['translations.viewAny'],
                    menu: {
                        admin: true,
                        icon: 'language',
                        text: 'translations.title',
                    },
                    breadcrumbs: [
                        {
                            name: 'translations.title',
                        },
                    ],
                },
            },
            {
                path: '/status-updates',
                component: EmptyRouterView,
                meta: {
                    admin: true,
                    permissions: ['status-update.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'statusUpdates.title',
                            path: '/status-updates',
                        },
                    ],
                    menu: {
                        admin: true,
                        icon: 'info-circle',
                        text: 'statusUpdates.title',
                    },
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/status-updates/StatusUpdates.vue'),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/status-updates/StatusUpdateForm.vue'),
                        meta: {
                            permissions: ['status-update.create'],
                            breadcrumbs: [
                                {
                                    name: 'newStatusUpdate.title',
                                },
                            ],
                        },
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/status-updates/StatusUpdate.vue'),
                        props: true,
                        meta: {
                            permissions: ['status-update.update'],
                            breadcrumbs: [
                                {
                                    prop: 'id',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: '/purchase-order-messages',
                component: EmptyRouterView,
                meta: {
                    admin: true,
                    permissions: ['messages.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'general.purchaseOrderMessages',
                            path: '/files',
                        },
                    ],
                    menu: {
                        admin: true,
                        icon: 'envelopes-bulk',
                        text: 'general.purchaseOrderMessages',
                    },
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/files/PurchaseOrderMessages.vue'),
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/files/file/PurchaseOrderMessage.vue'),
                        props: true,
                        meta: {
                            permissions: ['messages.viewAny'],
                            breadcrumbs: [
                                {
                                    prop: 'id',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: '/order-tracking-uploads',
                component: () => import('@/modules/files/OrderTrackingUploads.vue'),
                meta: {
                    admin: true,
                    permissions: ['messages.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'general.orderTrackingUploads',
                            path: '/order-tracking-uploads',
                        },
                    ],
                    menu: {
                        admin: true,
                        icon: 'file-excel',
                        text: 'general.orderTrackingUploads',
                    },
                },
            },
            {
                path: '/media',
                component: () => import('@/modules/files/Media.vue'),
                meta: {
                    admin: true,
                    permissions: ['media.viewAny'],
                    breadcrumbs: [
                        {
                            name: 'general.purchaseOrderForms',
                            path: '/media',
                        },
                    ],
                    menu: {
                        admin: true,
                        icon: 'file-pdf',
                        text: 'general.purchaseOrderForms',
                    },
                },
            },
            {
                path: '/notifications',
                component: () => import('@/modules/notifications/Notifications.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'notifications.title',
                        },
                    ],
                },
            },
        ],
    },
    {
        path: '',
        component: Unauthenticated,
        name: 'unauthenticated',
        children: [
            {
                path: 'login',
                name: 'login',
                component: Login,
            },
            {
                path: 'forgotten-username',
                component: () => import('@/modules/unauthenticated/forgotten-username/ForgottenUsername.vue'),
            },
            {
                path: 'password-reset-request',
                component: () => import('@/modules/unauthenticated/password-reset-request/PasswordResetRequest.vue'),
            },
            {
                path: 'password-reset',
                component: () => import('@/modules/unauthenticated/password-reset/PasswordReset.vue'),
            },
            {
                path: 'account-activation',
                component: () => import('@/modules/unauthenticated/account-activation/AccountActivation.vue'),
            },
        ],
    },
];
