import { getPurchasingGroups } from '@/modules/purchase-orders/products.api.js';

export function usePurchasingGroups() {
    const fetchPurchasingGroups = async callback => {
        try {
            const { data } = await getPurchasingGroups();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchPurchasingGroups,
    };
}
