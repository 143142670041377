<template>
    <div class="authenticated-view flex w-full overflow-hidden">
        <Sidebar />
        <div class="flex flex-col items-center relative w-full overflow-hidden">
            <Topbar />

            <div class="flex flex-col h-full items-start overflow-y-auto overflow-x-hidden p-4 sm:px-10 sm:py-8 w-full">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script setup>
import Sidebar from '@/components/layout/sidebar/Sidebar.vue';
import Topbar from '@/components/layout/topbar/Topbar.vue';
import { onMounted } from 'vue';
import { useEcho } from '@/composables/use-echo.js';

onMounted(() => {
    useEcho().initialize();
});

</script>
