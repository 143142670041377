import { deepFreezeObject } from '@/utils';

export const CONSTANTS = deepFreezeObject({
    STATUS: {
        PENDING: 'pending',
        ACTIVE: 'active',
        BLOCKED: 'blocked',
    },
    DEFAULT_LOCALE: 'en-US',
    LANGUAGES: {
        'en-US': 'English (US)',
        'ja-JP': 'Japanese (Japan)',
    },
    MIME_TYPE: {
        XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        CSV: 'text/csv',
        PDF: 'application/pdf',
    },
    ROLE: {
        ADMIN: 2,
        HEADQUARTER_ADMIN: 3,
        VENDOR: 4,
    },
});
