<template>
    <div :title="`${unreadNotificationCount} unread notifications`">
        <router-link
            to="/notifications"
        >
            <span class="relative inline-block">
                <Icon
                    class="h-5 w-5"
                    :class="{
                        'text-blue-dark':unreadNotificationCount > 0
                    }"
                    name="bell"
                />
                <span
                    v-if="unreadNotificationCount > 0"
                    class="absolute top-0 right-0 inline-flex items-center justify-center text-xs leading-none text-primary border border-1 border-primary-dark bg-white transform translate-x-1/2 -translate-y-1/2 rounded-full h-5 w-5"
                >
                    {{ unreadNotificationCount > 99 ? 99 : unreadNotificationCount }}
                </span>
            </span>
        </router-link>
    </div>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import { useGlobalStore } from '@/stores/global-store.js';
import { computed } from 'vue';

const store = useGlobalStore();

const unreadNotificationCount = computed(() => store.user.unreadNotificationCount);

</script>
