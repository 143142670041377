<template>
    <div
        v-if="statusUpdates.length"
        class="w-full text-green-dark bg-green-lightest border border-green-light p-3 text-sm px-4 sm:px-10 flex items-start items-center"
    >
        <Icon name="info-circle" />
        <ul class="ml-3">
            <li
                v-for="statusUpdate in statusUpdates"
                :key="statusUpdate.id"
            >
                {{ statusUpdate.content }}
            </li>
        </ul>
    </div>
</template>
<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import { useOptionsStore } from '@/stores/options-store.js';

const { statusUpdates } = useOptionsStore();

</script>
