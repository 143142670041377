<template>
    <form
        ref="form"
        autocomplete="off"
        novalidate
        @submit="onSubmit($event)"
    >
        <slot
            class="text-center"
            name="header"
        />
        <slot />
        <footer>
            <slot name="footer" />
        </footer>
    </form>
</template>

<script setup>
import { useFormValidation } from '@/composables/use-form-validation';
import { ref } from 'vue';

const props = defineProps({
    formValidate: {
        type: Function,
        default: null,
    },
});

const emit = defineEmits([
    'formSubmit',
]);

const form = ref(null);
const {
    errors,
    triggerValidation,
} = useFormValidation();

const onSubmit = event => {
    event.preventDefault();

    const requiredInputNodes = form.value.querySelectorAll('input[required]');

    if (props.formValidate) {
        props.formValidate();
    } else {
        triggerValidation(requiredInputNodes);
    }

    const isValid = Array.from(requiredInputNodes).every(node => !Boolean(errors[node.getAttribute('id')]));

    if (isValid) {
        emit('formSubmit');
    }
};

</script>
