import { useGlobalStore } from '@/stores/stores.js';
import { formatCurrency, formatPercent } from '@/utils';

export function useNumberUtils(currencyCode = null) {
    const store = useGlobalStore();
    const isDecimalAllowed = currencyCode ? currencyCode !== 'JPY' : false;

    const parseNumber = value => {
        if (!value) {
            return 0;
        }

        return isDecimalAllowed ? Math.floor(parseFloat(value) * 100 ) / 100 : parseInt(value, 10);
    };

    const numberToString = value => {
        if (!value || !String(value).match(/[0-9]+(\.[0-9]*)?/)) {
            return '0';
        }

        return isDecimalAllowed ? String(value) : String(parseNumber(value));
    };

    const getMinValue = (value, maxValue, minValue = 0) => Math.min(maxValue, Math.max(minValue, value));
    const formatAsCurrency = (number, currency) => formatCurrency(parseNumber(number), store.userLocale, currency || 'USD');
    const formatAsPercentage = number => formatPercent(parseFloat(number), store.userLocale);

    const normalizeDecimalSeparator = (value, maxValue) => {
        const regex = /[,|.]/;

        if (!regex.test(value)) {
            return getMinValue(parseInt(value, 10), maxValue);
        }

        const [wholeNumberPart, decimalPart] = String(value).split(regex, 2);

        return `${getMinValue(parseInt(wholeNumberPart, 10), maxValue)}.${decimalPart.replaceAll(/[,|.]/g, '').substring(0, 2)}`;
    };

    return {
        isDecimalAllowed,
        parseNumber,
        numberToString,
        formatAsCurrency,
        formatAsPercentage,
        normalizeDecimalSeparator,
        getMinValue,
    };
}
