<template>
    <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div>
            <div
                v-if="state.show"
                class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-opacity-5 overflow-hidden p-4 border border-grey-light"
            >
                <div class="flex items-center">
                    <div class="flex-shrink-0 ">
                        <Icon
                            class="h-4 w-4"
                            :class="state.textColor"
                            :name="state.iconName"
                        />
                    </div>
                    <div class="ml-3">
                        <div
                            class="text-sm"
                            :class="state.textColor"
                        >
                            {{ state.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script setup>

import {
    computed, onMounted, reactive,
} from 'vue';
import Icon from '@/components/common/icon/Icon.vue';

const props = defineProps({
    toast: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        default: 'default',
        validator(value) {
            return ['default', 'info', 'success', 'warning', 'error'].includes(value);
        },
    },
    icon: {
        type: String,
        default: 'xmark-circle',
    },
    index: {
        type: [String, Number],
        required: true,
    },
    mode: {
        type: String,
        default: 'click',
        validator(value) {
            return ['click', 'fade'].includes(value);
        },
    },
    show: {
        type: [String, Number],
        default: 6000,
    },
});

const emit = defineEmits([
    'dismissToast',
]);

const type = computed(() => {
    return props.toast.type ? props.toast.type : props.type;
});

const iconName = computed(() => {

    if (props.toast.icon) {
        return props.toast.icon;
    }

    if (type.value === 'success') {
        return 'check';
    }

    if (type.value === 'info') {
        return 'circle-info';
    }

    if (type.value === 'warning') {
        return 'exclamation';
    }

    return props.icon;
});

const textColor = computed(() => {

    if (props.toast.type === 'success') {
        return 'text-green';
    }

    if (props.toast.type === 'info') {
        return 'text-blue';
    }

    if (props.toast.type === 'warning') {
        return 'text-yellow';
    }

    if (props.toast.type === 'error') {
        return 'text-orange';
    }

    return 'text-primary';
});

const state = reactive({
    show: true,
    text: props.toast.text,
    type: props.toast.type ? props.toast.type : props.type,
    iconName,
    textColor,
});

const dismissToast = () => {
    state.show = false;
    document.removeEventListener('click', dismissToast);
    emit('dismissToast');
};

const dismiss = () => {
    if (props.mode === 'fade') {
        setTimeout(() => dismissToast(), props.show);
    }

    if (props.mode === 'click') {
        document.addEventListener('click', dismissToast);
    }
};

onMounted(async () => {
    dismiss();
});
</script>
