import { getDeliveryLocations } from '@/modules/purchase-orders/products.api.js';

export function useDeliveryLocations() {
    const fetchDeliveryLocations = async callback => {
        try {
            const { data } = await getDeliveryLocations();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchDeliveryLocations,
    };
}
