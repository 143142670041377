import { useI18n } from 'vue-i18n';

/**
 * Flattens a deeply nested object
 * @param {Object} obj
 * @param {string} path
 * @return {Object}
 */
export function flattenObject(obj = {}, path = '') {
    if (typeof obj === 'string') {
        return {
            [path]: obj,
        };
    }

    return Object.keys(obj).reduce((accumulatorObj, currentKey) => ({
        ...accumulatorObj,
        ...flattenObject(obj[currentKey], path ? `${path}.${currentKey}` : currentKey),
    }), {});
}

/**
 * Debounces a task for a given timeout period
 * @param {Function} func
 * @param {number} timeout
 * @return {Function}
 */
export function debounce(func, timeout = 300) {
    let timer;

    return (...args) => {
        window.clearTimeout(timer);

        timer = window.setTimeout(() => {
            func(...args);
        }, timeout);
    };
}

/**
 * Maps an object to a new object
 * @param {Object} obj
 * @param {Function} mapFunc
 * @return {Object}
 */
export function mapObject(obj, mapFunc) {
    return Object.fromEntries(Object.entries(obj).map(mapFunc));
}

/**
 * Sorts an object by property value
 * @param {Object} obj
 * @param {Function} sortFunc
 * @return {Object}
 */
export function sortObject(obj, sortFunc) {
    return Object.fromEntries(Object.entries(obj).sort(sortFunc));
}

/**
 * Filters object by property value
 * @param {Object} obj
 * @param {Function} filterFunc
 * @return {Object}
 */
export function filterObject(obj, filterFunc) {
    return Object.fromEntries(Object.entries(obj).filter(filterFunc));
}

/**
 * Returns unique array of primitive values
 * @param {*[]} data
 * @return {*[]}
 */
export function unique(data) {
    return [...new Set(data)];
}

/**
 * Returns a language name for a given locale and language code
 * @param {string} locale
 * @param {string} languageCode
 * @return {string}
 */
export function formatLanguageName(locale, languageCode) {
    return new Intl.DisplayNames(locale, {
        type: 'language',
    }).of(languageCode);
}

/**
 * Returns a region name for a given locale and region code
 * @param {string} locale
 * @param {string} regionCode
 * @return {string}
 */
export function formatRegionName(locale, regionCode) {
    return new Intl.DisplayNames(locale, {
        type: 'region',
    }).of(regionCode);
}

/**
 * Checks if a given value is a JS Object
 * @param value
 * @return {boolean}
 */
export function isObject(value) {
    return value != null && value.constructor.name === 'Object';
}

/**
 * Checks if a given value is a JS array
 * @param {*} value
 * @return {boolean}
 */
export function isArray(value) {
    return Array.isArray(value);
}

/**
 * Recursively freezes a deeply nested object
 * @param {Object} obj
 * @return {Object}
 */
export function deepFreezeObject(obj) {
    Object.entries(obj).forEach(([key, value]) => {
        if (isObject(value) || isArray(value) && !Object.isFrozen(value)) {
            deepFreezeObject(value);
        }
    });

    return Object.freeze(obj);
}

/**
 * Converts a string to camel case
 * @param string
 * @return {*}
 */
export function toCamelCase(string) {
    return string.split('_')
        .map((word, index) => index === 0 ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join('');
}

/**
 * Converts a string to snake case
 * @param {string} string
 * @return {string}
 */
export function toSnakeCase(string) {
    return string.split(/(?=[A-Z0-9])/).join('_').toLowerCase();
}

/**
 * Converts a string to kebab case
 * @param string
 * @return {string}
 */
export function toKebabCase(string) {
    return string.split(/(?=[A-Z0-9])/).join('-').toLowerCase();
}

/**
 * Interpolates a string with variables
 * @param {string} string
 * @param {Object} params
 * @return {string}
 */
export function interpolate(string = '', params = {}) {
    let interpolatedString = string;

    for (const param in params) {
        if (params.hasOwnProperty(param)) {
            interpolatedString = interpolatedString.replace(new RegExp(`{${param}}`, 'g'), params[param]);
        }
    }

    return interpolatedString;
}

/**
 * Returns currency symbol for a given locale and currency code
 * @param {string} locale
 * @param {string} currency
 * @return {string}
 */
export function getCurrencySymbol(locale, currency) {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).formatToParts().find(({ type }) => type === 'currency').value;
}

/**
 * Formats number as currency for a given locale and currency code
 * @param {number} number
 * @param {string} locale
 * @param {string} currency
 * @return {string}
 */
export function formatCurrency(number, locale, currency) {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(number);
}

/**
 * Formats number with a thousand separator for a given locale
 * @param {number} number
 * @param {string} locale
 * @return {string}
 */
export function formatNumber(number, locale) {
    return new Intl.NumberFormat(locale).format(number);
}

/**
 * Formats number as percentage
 * @param {number} number
 * @param {string} locale
 * @return {string}
 */
export function formatPercent(number, locale) {
    return (number / 100).toLocaleString(locale, {
        style: 'percent',
        signDisplay: 'exceptZero',
        maximumFractionDigits: 2,
    });
}

/**
 * Triggers file download in a browser
 * @param {string} fileName
 * @param {Blob} blobData
 * @return {Promise}
 */
export async function triggerFileDownload(fileName, blobData) {
    return new Promise(resolve => {
        const url = window.URL.createObjectURL(blobData);
        const linkElement = document.createElement('a');

        linkElement.download = fileName.replace(/['‘’"“”]/g, '');
        linkElement.href = url;

        linkElement.click();
        linkElement.remove();
        window.URL.revokeObjectURL(url);
        resolve();
    });
}

/**
 * Converts JS date to date string compatible with native input of type date.
 * fr-CA is used only because it returns the right format as `yyyy-mm-dd`.
 * @param {Date} date
 * @return {string}
 */
export function toKebabDateString(date) {
    return date.toLocaleDateString('fr-CA');
}

/**
 * Format a date in yyyy-mm-dd
 * @param date
 * @returns {*}
 */
export function isoDate(date) {
    if (date === null) {
        return null;
    }

    return toLocalisedDate(date, 'fr-CA');
}

/**
 * Format a date in yyyy-mm-dd hh:ii:ss
 * @param date
 * @returns string
 */
export function isoDatetime(date) {

    if (date === null) {
        return null;
    }

    const regex = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/;

    const matches = date.match(regex);

    if (matches && matches.length > 2) {
        return `${matches[1]} ${matches[2]}`;
    }

    return date;
}

/**
 * Creates a locale aware datetime string
 * @param {Date} date
 * @param {string} languageTag
 * @param {Object} [options]
 * @return {string|null}
 */
export function toLocalisedDatetime(date, languageTag = 'en-US', options = {}) {

    if (date === null) {
        return null;
    }

    date = date instanceof Date ? date : new Date(date);

    if (!options.timeZone) {
        options.timeZone = 'UTC';
    }

    return date.toLocaleString(languageTag, options);
}

/**
 * Creates a locale aware date string
 * @param {Date} date
 * @param {string} languageTag
 * @param {Object} [options]
 * @return {string|null}
 */
export function toLocalisedDate(date, languageTag = 'en-US', options = {}) {

    if (date === null) {
        return null;
    }

    date = date instanceof Date ? date : new Date(date);

    if (!options.timeZone) {
        options.timeZone = 'Asia/Tokyo';
    }

    return date.toLocaleDateString(languageTag, options);
}

/**
 * Calcuate days between two dates
 * @param {Date} date1
 * @param {Date} date2
 * @return {int|null}
 */
export function diffInDays(date1, date2) {
    if (date1 === null || date2 === null) {
        return null;
    }

    date1 = date1 instanceof Date ? date1 : new Date(date1);
    date2 = date2 instanceof Date ? date2 : new Date(date2);

    return Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 86400));
}

export function orderTrackingChangeOrOriginal(model, field) {
    const current = model[field];

    return model.orderTrackingChanges.length && typeof model.orderTrackingChanges[0][field] !== 'undefined'
        ? model.orderTrackingChanges[0][field] : current;
}

export function displayOrderTrackingChange(model, field, formatter) {
    let current = model[field];
    let changed = orderTrackingChangeOrOriginal(model, field);

    if (current != changed) {
        current = formatter ? formatter(current) : current;
        changed = formatter ? formatter(changed) : changed;

        return `<s class="text-grey">${current}</s> / ${changed}`;
    }

    return formatter ? formatter(current) : current;
}

export function toYesNo(boolisch) {

    const { t } = useI18n();

    return Boolean(boolisch) ? t('general.yes') : t('general.no');
}

export function base64ToBlob(string, type) {
    const binaryString = atob(string);
    const len = binaryString.length;
    const binaryArray = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
    }

    return new Blob([binaryArray], {
        type: type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
}

export function convertCSVToArrayOfArrays(data, {
    header,
    separator,
}) {
    const csv = data;
    const array = [];
    const rows = csv.split(/(?!\B"[^"]*)\n(?![^"]*"\B)/g);

    rows.forEach((row, idx) => {
        const values = row.split(separator);
        const checkedAndConvertedValues = [];

        if (
            rows.length - 1 !== idx
            && (
                (!header && idx !== 0)
                || header
            )
        ) {
            values.forEach(value => {
                checkedAndConvertedValues.push(value);
            });

            array.push(checkedAndConvertedValues);
        }
    });

    return array;
}

export function getInitials(name) {
    if (!name?.length) {
        return '';
    }

    const nameArray = name.split(' ');
    const firstName = nameArray[0].charAt(0).toUpperCase();
    const lastName = nameArray[nameArray.length - 1].charAt(0).toUpperCase();

    return firstName + lastName;
}

export function base64Decode(string) {
    const binaryString = atob(string);

    const bytes = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    const decoder = new TextDecoder('utf-8');

    return decoder.decode(bytes);
}

;
