<template>
    <nav class="flex flex-col h-full text-secondary-light">
        <ul class="w-max">
            <SideMenuItem
                v-for="(item, index) in userMenuItems"
                :key="item.text"
                :item="item"
                :class="{'last:border-b': index + 1 === userMenuItems.length}"
            />
        </ul>

        <div
            v-if="showAdminMenu"
            class="mt-auto"
        >
            <h3 class="hidden md:block my-2 px-3 md:px-9 opacity-60">
                {{ $t('general.admin') }}
            </h3>
            <ul class="w-full">
                <SideMenuItem
                    v-for="item in adminMenuItems"
                    :key="item.text"
                    :item="item"
                />
            </ul>
        </div>
        <div
            class="flex flex-col text-sm w-full"
            :class="showAdminMenu ? 'mt-10': 'mt-auto'"
        >
            <div class="h-px bg-gradient-to-r from-primary via-secondary-dark to-primary" />
            <router-link
                to="/logout"
                class="flex hover:text-white items-center justify-center md:justify-start md:px-9 px-3 py-3 w-full"
            >
                <Icon
                    name="sign-out-alt"
                    class="md:mr-4"
                />
                <span class="hidden md:inline-block">{{ $t('general.buttonLogout') }}</span>
            </router-link>
        </div>
    </nav>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import SideMenuItem from '@/components/layout/sidebar/side-menu/SideMenuItem.vue';
import { useAuth } from '@/composables/use-auth.js';
import { useRouter } from 'vue-router';

const router = useRouter();
const { hasPermission } = useAuth();

const authenticatedRoutes = router.getRoutes().find(({ name }) => name === 'authenticated').children;
const getAuthorizedRoutes = routes => routes.filter(({ meta }) => !meta?.permissions ? true : meta.permissions.some(hasPermission));
const getMenuRoutes = (routes, admin) => getAuthorizedRoutes(routes.filter(({ meta }) => meta?.menu ? (admin ? meta.menu.admin : !meta.menu.admin) : false));
const mapMenuItem = ({
    path,
    meta,
    children,
}) => ({
    path,
    text: meta.menu.text,
    icon: meta.menu.icon,
    children,
});

const userMenuItems = getMenuRoutes(authenticatedRoutes).map(mapMenuItem);
const adminMenuItems = getMenuRoutes(authenticatedRoutes, true).map(mapMenuItem);

const showAdminMenu = hasPermission('admin') || hasPermission('headquarter');

</script>
