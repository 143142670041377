import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { useGlobalStore } from '@/stores/global-store.js';
import { api } from '@/api/api.js';

export function useEcho() {
    const store = useGlobalStore();

    const isEnabled = () => import.meta.env.VITE_PUSHER_APP_KEY && import.meta.env.VITE_PUSHER_APP_CLUSTER;

    const setupEcho = () => {

        if (!isEnabled()) {
            console.log('Echo not enabled.');

            return;
        }

        const { accessToken } = store;

        window.Pusher = Pusher;
        window.Pusher.logToConsole = true;

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
            forceTLS: true,
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        api.post('/broadcasting/auth', {
                            socketId,
                            channelName: channel.name,
                        }, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        })
                            .then(response => {
                                callback(null, response);
                            })
                            .catch(error => {
                                callback(error);
                            });
                    },
                };
            },
        });
    };

    const getSocketId = () => window.Echo.socketId();

    const getApiHeader = () => [{
        key: 'X-Socket-ID',
        value: getSocketId(),
    }];

    const initialize = () => {
        if (!isEnabled()) {
            return;
        }

        window.Echo.private(`App.Modules.Users.Models.User.${store.user.id}`)
            .notification(() => store.setUnreadNotificationCount(store.user.unreadNotificationCount + 1));
    };

    const listenPrivateChannel = (channel, events) => {
        if (!isEnabled()) {
            return;
        }

        leavePrivateChannel(channel);

        const connector = window.Echo.private(channel);

        events.forEach(event => connector.listen(event.key, event.callback));
    };

    const leavePrivateChannel = channel => {
        if (!isEnabled()) {
            return;
        }

        window.Echo.leave(channel);

        return 1;
    };

    return {
        setupEcho,
        initialize,
        isEnabled,
        listenPrivateChannel,
        leavePrivateChannel,
        getSocketId,
        getApiHeader,
    };
}
