import { getPublishedStatusUpdates } from '@/modules/status-updates/status-updates.api.js';

export function useStatusUpdates() {

    const fetchPublishedStatusUpdates = async callback => {
        try {
            const { data } = await getPublishedStatusUpdates();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchPublishedStatusUpdates,
    };
}
