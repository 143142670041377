import { api } from '@/api/api.js';
import { CONSTANTS } from '@/constants.js';
import { getTranslations } from '@/modules/translations/translations.api.js';
import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

let i18n = null;

export function setUpI18n(userLocale) {
    const locale = userLocale || CONSTANTS.DEFAULT_LOCALE;
    const numberFormats = {
        'en-US': {
            currency: {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol',
            },
        },
        'ja-JP': {
            currency: {
                style: 'currency',
                currency: 'JPY',
                currencyDisplay: 'symbol',
            },
        },
    };

    i18n = createI18n({
        locale,
        fallbackLocale: locale,
        messages: {
            [locale]: null,
        },
        numberFormats,
    });

    setLanguage(locale);

    return i18n;
}

export function setLanguage(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
        console.warn(`Can't set locale ${locale} as it's not in the available locale list`);

        return;
    }

    i18n.global.locale.value = locale;

    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     */
    api.defaults.headers.common['x-accept-language'] = locale;
    document.querySelector('html').setAttribute('lang', locale);
}

export async function loadLocaleMessages(locale) {
    const messages = await getTranslations(locale, {
        headers: {
            'x-accept-language': locale,
        },
    });

    i18n.global.setLocaleMessage(locale, messages);
}

export async function switchLanguage(locale) {
    if (!i18n.global.messages.value[locale]) {
        await loadLocaleMessages(locale);
    }

    setLanguage(locale);

    return nextTick();
}

export {
    i18n,
};
