<template>
    <!-- small screen -->
    <div class="flex w-full items-center justify-between px-4 sm:pl-10 md:hidden py-4">
        <AsicsLogo class="sm:h-7 md:h-8" />

        <div
            class="flex pr-4 gap-x-4 md:gap-x-2 items-center justify-end md:hidden"
        >
            <NotificationIcon class="md:hidden" />

            <!-- Separator -->
            <div
                class="h-6 w-px bg-grey"
                aria-hidden="true"
            />

            <CurrentUser class="md:hidden" />
        </div>
    </div>

    <!-- larger screen -->
    <div class="bg-grey-light flex sm:font-bold max-h-10 md:max-h-[4rem] h-full items-center justify-between px-4 sm:px-10 w-full">
        <Breadcrumbs />

        <div
            class="flex flex-1 gap-x-4 items-center justify-end"
        >
            <div
                v-if="isStaging"
                class="bg-orange-lightest border border-orange-light text-orange-dark text-xs font-normal py-1 px-2 text-center space-y-3"
            >
                <p>
                    <strong>Staging environment.</strong>
                    For production, please visit:
                    <a
                        href="https://dpp.asics.com"
                        class="underline"
                    >https://dpp.asics.com</a>
                </p>
            </div>

            <NotificationIcon class="hidden md:flex" />

            <!-- Separator -->
            <div
                class="hidden md:block lg:h-6 lg:w-px lg:bg-grey"
                aria-hidden="true"
            />

            <CurrentUser class="hidden md:flex" />
        </div>
    </div>

    <StatusUpdateBanner />
</template>

<script setup>
import Breadcrumbs from '@/components/layout/topbar/breadcrumbs/Breadcrumbs.vue';
import NotificationIcon from '@/components/layout/topbar/NotificationIcon.vue';
import AsicsLogo from '@/assets/images/asics-logo.svg';
import CurrentUser from '@/components/layout/topbar/CurrentUser.vue';
import { useGlobalStore } from '@/stores/global-store.js';
import StatusUpdateBanner from '@/modules/status-updates/StatusUpdateBanner.vue';

const store = useGlobalStore();

const { isStaging } = store;

</script>
