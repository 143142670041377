<template>
    <div class="items-baseline">
        {{ store.user.name }}
        <span
            v-if="store.impersonator"
            class="text-xs font-normal text-grey-dark h-full ml-1 italic cursor-pointer"
            @click="leaveImpersonating"
        >
            <Icon name="refresh" />
            {{ store.impersonator.name }}
        </span>
    </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global-store.js';
import Icon from '@/components/common/icon/Icon.vue';

const store = useGlobalStore();

const leaveImpersonating = async () => {
    await store.leaveImpersonating();
    store.addToastMessage({
        text: `Authenticated as ${store.user.username}`,
        type: 'success',
    });
    window.location.replace('/home');
};

</script>
