import { guards } from '@/router/middleware/middleware-runner.js';
import {
    authMiddleware, loadMetaDataMiddleware, permissionMiddleware, translationsMiddleware,
} from '@/router/middleware/middlewares.js';
import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes.js';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL), // NOTE: BASE_URL is Vite.js specific env variable (https://vitejs.dev/guide/env-and-mode.html#env-variables)
    routes,
});

router.beforeEach(guards([authMiddleware, permissionMiddleware, translationsMiddleware, loadMetaDataMiddleware]));

export {
    router,
};
