export function required(value) {
    return Array.isArray(value) ? Boolean(value.length) && value.every(item => item != null && item !== '') : value != null && value !== '';
}

export function number(value) {
    return /^\d+$/.test(value);
}

export function decimal(value) {
    return /^\d+([.,]\d{1,2})?$/.test(value);
}

export function minlength(value, minimum) {
    return value.length >= minimum;
}

export function maxlength(value, maximum) {
    return value.length <= maximum;
}

export function email(value) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value);
}

export function min(value, minimum) {
    return Number(value) >= Number(minimum);
}

export function max(value, maximum) {
    return Number(value) <= Number(maximum);
}
