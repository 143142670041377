import { getPriceStatusControls } from '@/modules/purchase-orders/products.api.js';

export function usePriceStatusControls() {
    const fetchPriceStatusControls = async callback => {
        try {
            const { data } = await getPriceStatusControls();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchPriceStatusControls,
    };
}
