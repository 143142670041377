import {
    getAccountGroupCodes, getBuyingCompanies, getPartners, getVendors,
} from '@/modules/partners/partners.api.js';

export function usePartners() {

    const fetchPartners = async (callback, params) => {
        try {
            const { data } = await getPartners(params);

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchBuyingCompanies = async callback => {
        try {
            const { data } = await getBuyingCompanies();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchVendors = async (callback, params) => {
        try {
            const { data } = await getVendors(params);

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAccountGroupCodes = async callback => {
        try {
            const { data } = await getAccountGroupCodes();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchVendors,
        fetchPartners,
        fetchBuyingCompanies,
        fetchAccountGroupCodes,
    };
}
