<template>
    <li
        class="border-t border-white border-opacity-10"
    >
        <div>
            <router-link
                :to="item.path"
                :data-test-id="testId"
                class="flex items-center w-full text-lg p-3 md:px-9 hover:text-white hover:bg-white hover:bg-opacity-10"
            >
                <Icon
                    v-if="item.icon"
                    :name="item.icon"
                    class="text-base md:mr-4 h-5 w-5"
                />
                <span class="hidden md:inline-block">{{ $t(item.text) }}</span>
            </router-link>
        </div>

        <template
            v-if="expandSubMenu && subMenuItems.length > 1"
        >
            <div
                v-for="menu in subMenuItems"
                :key="menu.text"
            >
                <router-link
                    :to="menu.path"
                    class="flex items-center w-full text-lg secondary-menu-item p-3 hover:bg-grey-light hover:bg-opacity-20 hover:text-white"
                >
                    <div class="md:pl-14">
                        <Icon
                            v-if="menu.icon"
                            :name="menu.icon"
                            class="text-base md:mr-4 h-5 w-5"
                        />
                        <span class="hidden text-sm md:text-base md:inline-block">{{ $t(menu.text) }}</span>
                    </div>
                </router-link>
            </div>
        </template>
    </li>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import { computed } from 'vue';
import { useAuth } from '@/composables/use-auth.js';
import { useRoute } from 'vue-router';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    alwaysExpand: {
        type: Boolean,
        default: false,
    },
});

const testId = `menu-item-${props.item.path.replace('/', '')}`;

const { hasPermission } = useAuth();
const route = useRoute();

const mapMenuItem = route => ({
    path: `${props.item.path}/${route.path}`,
    text: route.meta.submenu.text,
    icon: route.meta.submenu.icon,
});

const subMenuItems = computed(() => {
    return props.item?.children?.filter(
        route => Boolean(route?.meta?.submenu))
        .filter(({ meta }) => !meta?.permissions ? true : meta.permissions.some(hasPermission))
        .map(mapMenuItem) ?? [];
});

const expandSubMenu = computed(() => {
    const matchingRoute = route.matched.filter( x => x.path === props.item.path) ?? [];

    return props.alwaysExpand || matchingRoute.length > 0;
});

</script>

<style scoped>
.router-link-active {
    @apply bg-white bg-opacity-10 text-white;
}

.router-link-active.secondary-menu-item {
    @apply  bg-secondary-light text-primary;
}

.router-link-exact-active {
    @apply cursor-auto;
}

</style>
