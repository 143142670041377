import { api } from '@/api/api.js';

export function getPartners(params) {
    return api.get('/partners', {
        params: {
            page: 1,
            perPage: 25,
            sort: 'name:asc',
            ...params,
        },
    });
}

export function getPartner(id) {
    return api.get(`/partners/${id}`);
}

export function addPartner(payload) {
    return api.post('/partners', payload);
}

export function updatePartner(id, payload) {
    return api.patch(`/partners/${id}`, payload);
}

export function getBuyingCompanies() {
    return api.get('/buying-companies');
}

export function getVendors() {
    return api.get('/vendors');
}

export function getAccountGroupCodes() {
    return api.get('/account-group-codes');
}

export function downloadPartners(params) {
    return api.get('/partners/download', {
        responseType: 'blob',
        params,
    });
}
