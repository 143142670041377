import { transformToCamelCase, transformToSnakeCase } from '@/api/transformers.js';
import { useGlobalStore } from '@/stores/stores.js';
import { isObject } from '@/utils.js';
import axios from 'axios';
import { useEcho } from '@/composables/use-echo.js';

const axiosInstance = axios.create({
    baseURL: `${import.meta.env.BASE_URL}api/v1`,
    timeout: 180000,
});

let apiReady = false;
let router;

function setupAxiosInterceptors() {
    axiosInstance.interceptors.request.use(requestConfig => {
        const store = useGlobalStore();
        const { accessToken } = store;

        store.clearApiErrors();

        if (accessToken) {
            requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        }

        if (requestConfig.params) {
            requestConfig.params = transformToSnakeCase(requestConfig.params);
        }

        if (requestConfig.data) {
            requestConfig.data = transformToSnakeCase(requestConfig.data);
        }

        if (useEcho().isEnabled()) {
            const socketHeaders = useEcho().getApiHeader();

            socketHeaders.forEach(header => requestConfig.headers[header.key] = header.value);
        }

        return requestConfig;
    });

    axiosInstance.interceptors.response.use(response => {
        if (response.data && response.headers['content-type'] === 'application/json' && !response.config.url.includes('/translations') && !response.config.url.includes('/order-tracking-activity')) {
            if (response.data) {
                response.data = transformToCamelCase(response.data);
            }
        }

        return Promise.resolve(response.headers.has('content-disposition') ? response : response.data);
    }, async responseError => {
        const store = useGlobalStore();
        const {
            config: originalRequest,
            response,
        } = responseError;

        if (response.status === 503 || store.inMaintenanceMode) {
            store.setMaintenanceMode(true);

            if (!originalRequest.url.includes('maintenance')) {
                router.push('/maintenance');
            }

            return Promise.resolve(responseError);
        }

        const error = response?.data ?? responseError;

        if (isObject(error.errors)) {
            store.setApiErrors(error.errors);
        }

        if (response.status >= 500) {
            const text = error.message ? error.message : 'Something went wrong';

            store.addToastMessage({
                text,
                type: 'error',
            });
        }

        if (response.status === 404) {
            store.addToastMessage({
                text: 'Resource not found',
                type: 'error',
            });
        }

        if (response.status > 400 && originalRequest.url.includes('/authentication/refresh')) {
            // If token refresh endpoint returns status code greater than 400 (401 or 422),
            // it means that refresh token has expired, so we redirect to login page
            store.clearAuthUserState();

            await router.push('/login');
        }

        if (response.status === 401 && !originalRequest.url.includes('/authentication/login') && !originalRequest._retry) {
            // If unauthenticated (invalid or expired access token) and not retrying the original request,
            // call token refresh endpoint and retry original request
            originalRequest._retry = true;

            await store.refreshToken();

            return axiosInstance(originalRequest);
        }

        return Promise.reject(error);
    });
}

function setUpApi(routerInstance) {
    if (apiReady) {
        return;
    }

    router = routerInstance;

    setupAxiosInterceptors();

    apiReady = true;
}

export {
    setUpApi,
    axiosInstance as api,
};
