import { api } from '@/api/api.js';

export function getStatusUpdates(params) {
    return api.get('/status-updates', {
        params: {
            page: 1,
            perPage: 25,
            sort: 'id:desc',
            ...params,
        },
    });
}

export function getStatusUpdate(id) {
    return api.get(`/status-updates/${id}`);
}

export function addStatusUpdate(payload) {
    return api.post('/status-updates', payload);
}

export function updateStatusUpdate(id, payload) {
    return api.patch(`/status-updates/${id}`, payload);
}

export function deleteStatusUpdate(id) {
    return api.delete(`/status-updates/${id}`);
}

export function getPublishedStatusUpdates() {
    return api.get('/status-updates/published');
}
