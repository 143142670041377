export function useDropdownOptions() {
    const getDropdownOptions = (data, value, text) => data.map(item => ({
        value: item[value],
        text: item[text],
        translated: true,
    })).sort((a, b) => a.text.localeCompare(b.text));

    return {
        getDropdownOptions,
    };
}
