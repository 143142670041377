import {
    isArray, isObject, toCamelCase, toSnakeCase,
} from '@/utils.js';

export function transformToCamelCase(data) {
    if (isObject(data)) {
        const obj = {};

        for (const [key, value] of Object.entries(data)) {
            obj[toCamelCase(key)] = transformToCamelCase(value);
        }

        return obj;
    } else if (isArray(data)) {
        return data.map(item => transformToCamelCase(item));
    }

    return data;
}

export function transformToSnakeCase(data) {
    if (isObject(data)) {
        const obj = {};

        for (const [key, value] of Object.entries(data)) {
            obj[toSnakeCase(key)] = transformToSnakeCase(value);
        }

        return obj;
    } else if (isArray(data)) {
        return data.map(item => transformToSnakeCase(item));
    }

    return data;
}
