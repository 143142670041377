import { api } from '@/api/api.js';

export function getProducts(params) {
    return api.get('/products', {
        params: {
            page: 1,
            perPage: 100,
            sort: 'id:asc',
            ...params,
        },
    });
}

export function getProduct(id) {
    return api.get(`/products/${id}`);
}

export function getRegions() {
    return api.get('/regions');
}

export function getDeliveryLocations() {
    return api.get('/delivery-locations');
}

export function getPurchasingGroups() {
    return api.get('/purchasing-groups');
}

export function getModeOfTransports() {
    return api.get('/mode-of-transports');
}

export function getPriceStatusControls() {
    return api.get('/price-status-controls');
}

export function getReasonCodes() {
    return api.get('/reason-codes');
}

export function getReleaseStatuses() {
    return api.get('/release-statuses');
}

export function downloadProductList(params) {
    return api.get('/products/downloads/list', {
        responseType: 'blob',
        params,
    });
}

export function downloadProductListOnQueue(params) {
    params.queue = true;

    return api.get('/products/downloads/list', {
        params,
    });
}

export function downloadExportedFile(fileName) {
    return api.get(`/downloads/exported-files/${fileName}`);
}
