<template>
    <div
        aria-live="assertive"
        class="absolute z-[1000] inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <template
                v-for="(toast, index) in toasts"
                :key="index"
            >
                <Toast
                    :toast="toast"
                    :index="index"
                    @dismissToast="dismissToast(index)"
                />
            </template>
        </div>
    </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global-store.js';
import Toast from '@/components/common/toasts/Toast.vue';

const store = useGlobalStore();

const emit = defineEmits([
    'dismissToast',
]);

const toasts = store.toastMessages;

const dismissToast = index => {
    store.dismissToastMessage(index);
};

</script>
