<template>
    <div class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-grey-light rounded-lg sm:border sm:px-12 sm:py-8 p-4 w-full sm:w-[31rem]">
        <div class="flex flex-col items-center mb-5">
            <router-link to="/">
                <AsicsLogo class="text-primary h-14" />
            </router-link>
        </div>
        <header class="flex flex-col items-center space-y-6">
            <h1 class="text-center">
                <slot name="title">
                    Digital Procurement Portal
                </slot>
            </h1>

            <div
                v-if="isStaging"
                class="w-full bg-orange-lightest border border-orange-light text-orange-dark  text-xs p-2 text-center space-y-3"
            >
                <p><strong>Staging environment.</strong></p>
                <p>
                    For production, please visit:
                    <a
                        href="https://dpp.asics.com"
                        class="underline"
                    >https://dpp.asics.com</a>
                </p>
            </div>
        </header>

        <router-view />
    </div>
</template>

<script setup>
import AsicsLogo from '@/assets/images/asics-logo.svg';
import { useGlobalStore } from '@/stores/stores.js';

const store = useGlobalStore();

const { isStaging } = store;

</script>
