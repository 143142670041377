<template>
    <div
        class="flex relative"
        :class="[inline ? 'items-center' : 'flex-col', {'h-[5.125rem]': !flatStyle && !$attrs.class && !containerClass}, $attrs.class, containerClass]"
    >
        <label
            v-if="label"
            :for="id"
            class="text-sm mb-1 truncate"
            :class="{'mr-1': inline}"
        >
            {{ label }}
            <span
                v-if="showOptional && !isRequired && !disabled"
                class="text-grey-dark"
            >({{ $t('general.labelOptional') }})</span>
        </label>
        <div class="relative">
            <select
                :id="id"
                v-model="selected"
                class="px-1 py-[0.42875rem] border border-grey bg-white w-full"
                :class="{
                    'border-0 pl-0': flatStyle,
                    'px-1 py-0.5 border-primary rounded outline-none': inline,
                    'outline-none focus:border-secondary focus:ring-1 focus:ring-secondary': !flatStyle && !inline,
                    'border-orange-dark': errors[id],
                    'border-grey': !errors[id],
                }"
                :disabled="disabled"
                :required="isRequired"
                @change="onChange"
            >
                <option
                    v-if="!isRequired"
                    :value="null"
                >
                    {{ $t('general.dropDownDefault') }}
                </option>
                <option
                    v-for="option in dropDownOptions"
                    :key="option.value"
                    :value="option.value"
                >
                    {{ option.translated ? option.text : $t(option.text) }}
                </option>
            </select>
            <span
                v-if="disabled"
                class="absolute flex items-center justify-center -top-3 -right-3 bg-white text-grey border border-grey rounded w-6 h-6 leading-tight"
            >
                <Icon
                    name="lock"
                    class="text-xs align-baseline"
                />
            </span>
        </div>
        <span
            v-if="errors[id] || apiErrorMessages[id]"
            class="text-orange-dark text-xs mt-0.5 sentence-case"
        >
            {{ errors[id] || apiErrorMessages[id] }}
        </span>
    </div>
</template>

<script setup>
import Icon from '@/components/common/icon/Icon.vue';
import {
    computed, onMounted, reactive, ref, useAttrs,
} from 'vue';
import { useFormValidation } from '@/composables/use-form-validation.js';
import { useI18n } from 'vue-i18n';

const attrs = useAttrs();

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    options: {
        type: Array,
        default() {
            return [];
        },
    },

    modelValue: {
        type: [String, Number, Boolean],
        default: null,
    },

    id: {
        type: [String, Number],
        default() {
            return Date.now();
        },
    },

    label: {
        type: String,
        default: null,
    },

    inline: {
        type: Boolean,
        default: false,
    },

    flatStyle: {
        type: Boolean,
        default: false,
    },

    containerClass: {
        type: [String, Array, Object],
        default: null,
    },

    getOptions: {
        type: Function,
        default: null,
    },

    showOptional: {
        type: Boolean,
        default: false,
    },

    required: {
        type: Boolean,
        default: false,
    },

    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    'update:modelValue',
    'onChange',
    'inputBlur',
]);

const { t } = useI18n();
const {
    validate,
    errors,
    apiErrorMessages,
} = useFormValidation(t);

const selected = computed({
    get() {
        return props.modelValue;
    },

    set(value) {
        emit('update:modelValue', value);
    },
});

let dropDownOptions = reactive(props.options);
const getDropDownOptions = async () => {
    dropDownOptions = await props.getOptions();
};

const onBlur = event => {
    if (!props.disabled) {
        validate(props.id, event.target.value, props?.validationRules, props?.validationMessages);
    }

    emit('inputBlur', event.target.value);
};

const onChange = event => {
    emit('onChange', event.target.value);
};

onMounted(async () => {
    if (typeof props.getOptions === 'function') {
        await getDropDownOptions();
    }
});

const isRequired = props.validationRules ? props.validationRules.split('|').includes('required') : ref(props.required);

</script>

<style scoped>
select[disabled] {
    @apply text-grey focus:outline-none border-grey opacity-100 pointer-events-none;
}
</style>
