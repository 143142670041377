import { getRegions } from '@/modules/purchase-orders/products.api.js';

export function useRegions() {
    const fetchRegions = async callback => {
        try {
            const { data } = await getRegions();

            callback(data);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchRegions,
    };
}
