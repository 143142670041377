// All solid icons: https://fontawesome.com/icons?d=gallery&p=2&s=solid

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faBan,
    faBell,
    faBinoculars,
    faCalendarCheck,
    faCaretRight,
    faChartSimple,
    faCheck,
    faCheckCircle,
    faCircleInfo,
    faClockRotateLeft,
    faComputer,
    faCopy,
    faDownload,
    faEdit,
    faEnvelopesBulk,
    faExclamation,
    faExclamationCircle,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFile,
    faFileExcel,
    faFileLines,
    faFilePdf,
    faFilter,
    faHome,
    faIndustry,
    faInfoCircle,
    faLanguage,
    faLock,
    faPlus,
    faRefresh,
    faRoute,
    faSave,
    faSearch,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTimes,
    faTruck,
    faUpload,
    faUserPlus,
    faUsers,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';

function setUpIcons() {
    library.add(
        faBan,
        faBell,
        faCaretRight,
        faCalendarCheck,
        faCheckCircle,
        faCopy,
        faDownload,
        faFile,
        faFilePdf,
        faFileExcel,
        faEdit,
        faExclamationCircle,
        faFileLines,
        faHome,
        faIndustry,
        faLanguage,
        faLock,
        faPlus,
        faRoute,
        faSave,
        faSignOutAlt,
        faSort,
        faSortDown,
        faSortUp,
        faSpinner,
        faTruck,
        faUpload,
        faUserPlus,
        faUsers,
        faAngleLeft,
        faAngleRight,
        faAngleDoubleLeft,
        faAngleDoubleRight,
        faXmarkCircle,
        faCircleInfo,
        faTimes,
        faCheck,
        faExclamation,
        faEyeSlash,
        faEye,
        faSearch,
        faFilter,
        faEnvelopesBulk,
        faComputer,
        faInfoCircle,
        faExternalLinkAlt,
        faRefresh,
        faBinoculars,
        faChartSimple,
        faClockRotateLeft,
    );
}

export {
    setUpIcons,
};
